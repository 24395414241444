<script>
	import Logo from './Logo.svelte';
	import ShoppingBagIcon from './ShoppingBagIcon.svelte';
	import MobileMenu from './MobileMenu.svelte';
	import NavLinks from './NavLinks.svelte';
	import Search from './Search.svelte';

	export let isCompact = false;
</script>

<div class="top-bar">
	<div class="menu-item left">
		<div class="desktop-menu">
			<NavLinks desktop />
		</div>
		<div class="mobile-menu">
			<MobileMenu />
		</div>
	</div>

	<div class="menu-item logo">
		<Logo {isCompact} />
	</div>

	<div class="menu-item right">
		<ul>
			<li class="desktop-menu">
				<Search />
			</li>

			<li>
				<ShoppingBagIcon />
			</li>
		</ul>
	</div>
</div>

<style lang="scss">
	.top-bar {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 0.625em 1em;
		width: 100%;
		box-shadow: 0 8px 10px -10px #cccccc;
		z-index: 1000;

		.menu-item {
			flex-basis: 33.333%;
			list-style: none;
			padding: 0;
		}

		.logo {
			display: flex;
			place-content: center;
		}

		.right {
			margin-left: auto;
			display: flex;
			place-content: flex-end;

			ul {
				list-style: none;
				display: flex;
				padding: 0;
				margin: 0;
			}

			li {
				font-size: 1.2rem;
				margin-left: 1.5rem;
				align-self: baseline;
			}
		}

		.left {
			position: relative;
		}
	}

	.desktop-menu {
		display: none;
	}

	.mobile-menu {
		display: none;
	}

	@media (min-width: 768px) {
		.desktop-menu {
			display: block;
		}
	}

	@media (max-width: 767px) {
		.top-bar {
			.right {
				margin-right: 0;
				li {
					margin-left: 0;
				}
			}
		}

		.mobile-menu {
			display: block;
		}
	}
</style>
