<script>
	import QueensAwardLogo from '$lib/assets/images/queens-award-logo.svg';
	import barsSvg from '$lib/assets/images/bars.svg';
	import NavLinks from './NavLinks.svelte';
	import Search from './Search.svelte';
	import Sidebar from './Sidebar.svelte';

	let toggleSidebar;

	const SidebarFooterLinks = [
		{ href: '/about', text: 'About' },
		{ href: '/blog', text: 'Blog' },
		{ href: '/faqs', text: 'FAQs' },
		{ href: '/contact', text: 'Contact Us' }
	];
</script>

<button on:click={toggleSidebar} class="menu-icon" aria-label="Open the sidebar">
	<img src={barsSvg} alt="Open the sidebar" height="24px" width="21px" />
</button>

<Sidebar bind:toggleSidebar location="left">
	<div class="nav-icons">
		<ul>
			<li><Search /></li>
		</ul>
	</div>

	<NavLinks on:linkClick={toggleSidebar} />

	<div class="sidebar-footer">
		<ul>
			{#each SidebarFooterLinks as link}
				<li><a href={link.href} on:click={toggleSidebar}>{link.text}</a></li>
			{/each}
		</ul>
	</div>

	<a
		class="queens-award-container"
		href="https://www.thegazette.co.uk/content/pdf/queens-awards-enterprise-press-book-2020.pdf"
		target="_blank"
	>
		<img
			class="queens-award"
			src={QueensAwardLogo}
			alt="Queens Award Logo"
			width="80"
			height="112"
		/>
	</a>
</Sidebar>

<style lang="scss">
	.menu-icon {
		cursor: pointer;
		display: flex;
	}

	.sidebar-footer {
		ul {
			list-style: none;
			padding: 0;
			margin: 0;
		}

		a {
			display: flex;
			color: #777777;
			text-decoration: none;
			line-height: 3;

			&:hover {
				text-decoration: underline;
			}
		}
	}

	.queens-award {
		width: 5em;
		opacity: 0.5;
	}

	.queens-award-container {
		position: absolute;
		bottom: 1.25em;
		left: 50%;
		transform: translateX(-50%);
		height: min-content;
	}

	.nav-icons {
		margin-top: 2em;
		ul {
			list-style: none;
			display: flex;
			justify-content: end;
			padding: 0;
			margin-right: 1em;
		}
		a {
			color: black;
		}
	}
</style>
