<script>
	import QueensAwardLogo from '$lib/assets/images/queens-award-logo.svg';
	import PhoneSvg from '$lib/assets/images/phone.svg';
	import EnvelopeSvg from '$lib/assets/images/envelope.svg';
	import LocationSvg from '$lib/assets/images/location-pin.svg';
	import NewsletterForm from '$lib/components/NewsletterForm.svelte';
	import FooterLinks from '$lib/components/FooterLinks.svelte';

	const copyRight = `Copyright © ${new Date().getFullYear()} London Tradition Ltd. All rights reserved.`;
</script>

<footer>
	<div class="container">
		<div class="footer-content">
			<div class="top-row" />
			<div class="middle-row">
				<NewsletterForm />
				<FooterLinks />
				<a
					class="queens-award-container"
					href="https://www.thegazette.co.uk/content/pdf/queens-awards-enterprise-press-book-2020.pdf"
					target="_blank"
				>
					<img
						class="queens-award"
						src={QueensAwardLogo}
						alt="Queens Award Logo"
						width="128"
						height="180"
					/>
				</a>
			</div>

			<div class="bottom-row">
				<div class="footer-legal">
					<div class="copyright">{copyRight}</div>
					<div class="legal-links">
						<a href="/terms">Terms & Conditions</a>
						<span class="divider">|</span>
						<a href="/privacy">Privacy Policy</a>
						<span class="divider">|</span>
						<a href="/accessibility">Accessibility</a>
					</div>
				</div>

				<div class="footer-contact">
					<div class="info">
						<a href="tel:+442085339900">
							<img src={PhoneSvg} alt="Phone" height="13px" width="13px" />0208 533 9900
						</a>
					</div>
					<div class="info">
						<a href="mailto:support@londontradition.com">
							<img
								src={EnvelopeSvg}
								alt="Email"
								height="13px"
								width="13px"
							/>support@londontradition.com
						</a>
					</div>
					<div class="info">
						<a
							href="https://www.google.com/maps?q=London+Tradition%2C+96b+Wallis+Road%2C+London+E9+5LN"
							target="_blank"
						>
							<img src={LocationSvg} alt="Location" height="13px" width="13px" />96b Wallis Road,
							London E9 5LN
						</a>
					</div>
				</div>
			</div>
		</div>
	</div>
</footer>

<style lang="scss">
	.container {
		background-color: #f2f2f2;
		padding: 2em 1em;
		text-align: center;
		margin-top: 2em;
	}

	.footer-content {
		max-width: 1200px;
		margin: 0 auto;
	}

	.middle-row {
		display: flex;
		justify-content: space-between;
	}

	.bottom-row {
		display: flex;
		margin-top: 2em;
		font-size: 0.8rem;
		border-top: 1px solid #ccc;
		align-items: start;
		text-align: start;
		line-height: 2em;
	}

	.footer-legal {
		padding-top: 1em;
		font-size: 12px;
		flex: 1 0 auto;
	}

	.footer-contact {
		padding-top: 1em;
	}

	a {
		color: #000;
		text-decoration: none;
		align-items: center;
		&:hover {
			text-decoration: underline;
		}
	}

	.info {
		img {
			margin-right: 0.5em;
		}
	}

	.queens-award {
		max-width: 8em;
	}

	.queens-award-container {
		align-self: center;
		height: min-content;
	}

	.divider {
		margin: 0 0.5em;
	}

	@media (min-width: 768px) {
		.bottom-row {
			justify-content: space-between;
		}

		.footer-contact {
			display: inline-flex;
			flex-wrap: wrap;
			justify-content: flex-end;

			img {
				margin-left: 1em;
			}
		}
	}

	/* mobile styles */
	@media (max-width: 767px) {
		.middle-row {
			flex-direction: column;
			justify-content: center;
			align-items: center;
			* {
				margin: 1em 0;
			}
		}

		.info {
			padding: 0.25rem 0;
		}

		.bottom-row {
			flex-direction: column-reverse;
			margin-top: 0;
		}
	}
</style>
